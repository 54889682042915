$(document).foundation();
$('.read-more').click(function(){
	$('.more-content').show();
	$(this).hide();
});
$('.more-content .read-more').click(function(){
		$('.more-content').hide();
	$('.read-more').show();
});
$(window).on("scroll", function() {
	$(window).scrollTop();
	//console.log($(window).scrollTop());
	if ($(window).scrollTop() !== 0) {
	   $('.header-nav').addClass('stickyous');
	}else{
		$('.header-nav').removeClass('stickyous');
	}
});